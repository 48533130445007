import { template as template_90c46be4694e453d81409669d8defd0e } from "@ember/template-compiler";
const EmptyState = template_90c46be4694e453d81409669d8defd0e(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
