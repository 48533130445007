import { template as template_0aa372dbd86e4bc3b05269cf2748160c } from "@ember/template-compiler";
const FKLabel = template_0aa372dbd86e4bc3b05269cf2748160c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
