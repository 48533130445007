import { template as template_e550a66bbb7d4a8193b004f0bf2db894 } from "@ember/template-compiler";
const SidebarSectionMessage = template_e550a66bbb7d4a8193b004f0bf2db894(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
