import { template as template_bcd19e5fa88f405cb287f0955a43300f } from "@ember/template-compiler";
const FKControlMenuContainer = template_bcd19e5fa88f405cb287f0955a43300f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
